import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvForm} from "availity-reactstrap-validation";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Translate } from "../../../Enums/Tranlate";
import EmployeesService from "../../../../services/EmployeesService";

const CertificateModal = ({modal, setModal, setEmployee})=>{
    const [formData, setFormData] = useState({
        employee: '',
    })
    const [employeesOptions, setEmployeesOptions] = useState([])
    const employeesService = new EmployeesService()
    const lang = useSelector(state=> state.auth.lang)

    useEffect(()=>{
        employeesService?.getList().then(res=>{
            if(res?.status === 200){
                let data = res?.data?.data?.data?.map(emp=>{
                    return {
                        ...emp,
                        label: emp?.name,
                        value: emp.id
                    }
                })
                setEmployeesOptions(data)
            }
        })
    },[])

    const submit = (e) =>{
        e.preventDefault();
        setEmployee(formData?.employee)
        setModal()
    }

    return(
        <Modal className={lang === 'en' ? "en fade addProduct" : "ar fade addProduct"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={modal} onHide={()=>{
            setModal()
            }}>
            <AvForm
                    className='form-horizontal'
                    onValidSubmit={submit}>
            <Modal.Header>
            <Modal.Title>{Translate[lang]?.salary_certificate}</Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                onClick={()=>{
                    setModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <label className="text-label">
                                {Translate[lang].employee}
                            </label>
                            <Select
                                placeholder={Translate[lang]?.select}
                                options={employeesOptions}
                                value={formData.employee}
                                onChange={(e) => setFormData({...formData, employee: e})}
                            />
                        </Col>
                    </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={setModal} variant="danger light">
            {Translate[lang]?.close}
            </Button>
            <Button 
                    variant="primary" 
                    type='submit'
                    disabled={!formData?.employee}
                >{Translate[lang]?.add}</Button>
            </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default CertificateModal;