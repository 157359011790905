import { useEffect, useState } from "react";
import { Translate } from "../../../Enums/Tranlate";
import Loader from "../../../common/Loader";
import { useSelector } from "react-redux";
import BaseService from "../../../../services/BaseService";
import './style.scss'
import { Card } from "react-bootstrap";
import Profile from "./Profile";
import Benefits from "./Benefits";
import Allowances from "./Allowances";
import { useLocation } from "react-router-dom";
import EmployeesService from "../../../../services/EmployeesService";
import { toast } from "react-toastify";

const EmployeeProfile = () => {
    const tabs = ["profile", "benefits", "allowances"]
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingProfile, setLoadingProfile] = useState(false)
    const [selectTab, setSelectTab] = useState("profile")
    const employeesService = new EmployeesService()
    const lang = useSelector(state=> state.auth.lang)
    const location = useLocation()

    const fileHandler = (e) => {
        if(e.target.files?.length === 0){
            return
        }
        let files = e.target.files
        const filesData = Object.values(files)
        setLoading(true)
        if (filesData?.length) {
            new BaseService().postUpload(filesData[0]).then(res=>{
                if(res?.status === 200){
                    let avatar = res?.data?.url
                    employeesService.updateAvatar(location?.state?.id, {avatar: avatar}).then(res2=>{
                        if(res2?.status){
                            setData({...data, avatar: res?.data?.url})
                            toast.success(Translate[lang].updated_successfully)
                        }
                    })
                }
                setLoading(false)
            }).catch(()=> setLoading(false))
        }
    }

    useEffect(()=>{
        let id = location?.state?.id
        setLoadingProfile(true)
        employeesService.profile(id).then(res=>{
            if(res?.status){
                setData(res?.data?.data)
            }
            setLoadingProfile(false)
        }).catch(e=> setLoadingProfile(false))
    },[])

    if(loadingProfile){
        return <Loader />
    }

    return <div className="emp-profile">
    <Card>
        <Card.Body>
            <div className="profile-section">
                <div className="row">
                    <div className="col-lg-6 basic">
                        <div className="image-placeholder">	
                            <div className="avatar-edit">
                                <input type="file" onChange={(e) => fileHandler(e)} id={`imageUpload1`} /> 					
                                <label htmlFor={`imageUpload1`}  name=''></label>
                            </div>
                            <div className="avatar-preview2 m-auto">
                                <div id={`imagePreview`}>
                                    {(!data?.avatar && !loading) &&  <i className="la la-user"></i>}
                                    {(!!data?.avatar && !loading) &&  <img src={data?.avatar} alt='avatar' className='avatar' />}
                                    {loading && <div style={{
                                        marginLeft: lang === 'en' ? '5rem' : 'auto',
                                        marginRight: lang === 'ar' ? '5rem' : 'auto',
                                    }}> 
                                        <Loader />
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3 className='name mb-1'>{data?.name}</h3>
                            <p className='job-title mb-1'>{data?.job_title}</p>
                            <p className="department mb-2">{data?.department?.name}</p>
                            <p className='date-join'>{Translate[lang].date_of_join}: {data?.start_date?.split('T')[0]}</p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <ul class="personal-info">
                            <li>
                                <div class="title">{Translate[lang].civil_id}:</div>
                                <div class="text">
                                {data?.civil_id}
                                </div>
                            </li>
                            <li>
                                <div class="title">{Translate[lang].nationality}:</div>
                                <div class="text">{data?.nationality}</div>
                            </li>
                            <li>
                                <div class="title">{Translate[lang].personal_email}:</div>
                                <div class="text">{data?.personal_email}</div>
                            </li>
                            <li>
                                <div class="title">{Translate[lang].company_email}:</div>
                                <div class="text">{data?.company_email}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Card.Body>
        <div className="tabs-div">
            {tabs?.map((tab,index)=>{
                return <p
                key={index}
                className={`${tab === selectTab ? "activeP" : "" }`}
                onClick={() => setSelectTab(tab)}
              >
                {Translate[lang][tab]}
              </p>
            })}
        </div>
    </Card>

    {selectTab === "profile" && <Profile data={data} />}
    {selectTab === "benefits" && <Benefits employee={data} />}
    {selectTab === "allowances" && <Allowances employee={data} />}
    </div>
}
export default EmployeeProfile;