import { Badge } from "react-bootstrap";
import { Translate } from "../../../Enums/Tranlate";
import { useSelector } from "react-redux";

const CardItem = ({item, index}) =>{
    const lang = useSelector(state=> state.auth?.lang)

    return(
        <tr key={index} className='text-center'>
            <td>
                <strong>{item.id}</strong>
            </td>
            <td>
                {item?.name}
            </td>
            <td>{item?.job_title}</td>
            <td>{item?.department?.name}</td>
            <td>{item?.civil_id}</td>
            <td>
                <Badge style={{
                    background: item?.civil_status, color: item?.civil_status === 'yellow' ? '#000' : '#fff'
                }}>{item?.civil_status !== 'red' ? Translate[lang].valid : Translate[lang].expired}</Badge>
            </td>
            <td>
                <Badge style={{
                    background: item?.passport_status, color: item?.passport_status === 'yellow' ? '#000' : '#fff'
                }}>{item?.passport_status !== 'red' ? Translate[lang].valid : Translate[lang].expired}</Badge>
            </td>
            <td>
                <Badge style={{
                    background: item?.residency_status, color: item?.residency_status === 'yellow' ? '#000' : '#fff'
                }}>{item?.residency_status !== 'red' ? Translate[lang].valid : Translate[lang].expired}</Badge>
            </td>
            </tr>
    )
}
export default CardItem;