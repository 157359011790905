import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import './style.scss'
import { Translate } from "../../Enums/Tranlate";
import AdminService from "../../../services/AdminService";

const Dashboard = () => {
  const [formData ,setFormData] = useState({
    totalCompany: '0',
    totalPeople: '0',
    totalLeave: '0',
    totalAssets: '0',
    totalAdmins: '0',
    totalResignation: '0'
  })
  const [loading, setLoading] = useState(false)
  const lang = useSelector(state=> state.auth.lang)

  useEffect(()=>{
    setLoading(true)
    new AdminService().getDashboard().then(res=>{
      if(res?.status){
        setFormData(res.data.data)
      }
      setLoading(false)
    }).catch(()=> setLoading(false))
  },[])

  if(loading) {
    return <Row>
      {Object.entries(formData)?.map(data=>{
        return <Col className="col-md-4 col-sm-6">
          <Card style={{height: '128.75px'}}>
            <Card.Body>
              <div class="skeleton-loader">
                  <div class="loader-header"></div>
                  <div class="loader-content"></div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      })}
    </Row>
  }

  return (
        <div className="row dashboard">
          <div className="col-md-4 col-sm-6">
            <div className="card" >
              <div class="wave-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fill-opacity="1" d="M0,192L17.1,192C34.3,192,69,192,103,170.7C137.1,149,171,107,206,122.7C240,139,274,213,309,218.7C342.9,224,377,160,411,154.7C445.7,149,480,203,514,234.7C548.6,267,583,277,617,250.7C651.4,224,686,160,720,138.7C754.3,117,789,139,823,160C857.1,181,891,203,926,197.3C960,192,994,160,1029,138.7C1062.9,117,1097,107,1131,96C1165.7,85,1200,75,1234,85.3C1268.6,96,1303,128,1337,128C1371.4,128,1406,96,1423,80L1440,64L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path></svg>              
              </div>
              <div className="card-body">
                <h2>{Translate[lang].company}</h2>
                <i className='la la-building'></i>
                <div>
                  <span className="fs-16 font-w600">
                    {formData.totalCompany} {Translate[lang]?.departments}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="card">
              <div class="wave-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fill-opacity="1" d="M0,192L17.1,192C34.3,192,69,192,103,170.7C137.1,149,171,107,206,122.7C240,139,274,213,309,218.7C342.9,224,377,160,411,154.7C445.7,149,480,203,514,234.7C548.6,267,583,277,617,250.7C651.4,224,686,160,720,138.7C754.3,117,789,139,823,160C857.1,181,891,203,926,197.3C960,192,994,160,1029,138.7C1062.9,117,1097,107,1131,96C1165.7,85,1200,75,1234,85.3C1268.6,96,1303,128,1337,128C1371.4,128,1406,96,1423,80L1440,64L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path></svg>              
              </div>
              <div className="card-body">
                <h2>{Translate[lang].people}</h2>
                <i className='la la-users'></i>
                <div>
                  <span className="fs-16 font-w600">
                    {formData.totalPeople} {Translate[lang]?.employees}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="card">
            <div class="wave-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fill-opacity="1" d="M0,192L17.1,192C34.3,192,69,192,103,170.7C137.1,149,171,107,206,122.7C240,139,274,213,309,218.7C342.9,224,377,160,411,154.7C445.7,149,480,203,514,234.7C548.6,267,583,277,617,250.7C651.4,224,686,160,720,138.7C754.3,117,789,139,823,160C857.1,181,891,203,926,197.3C960,192,994,160,1029,138.7C1062.9,117,1097,107,1131,96C1165.7,85,1200,75,1234,85.3C1268.6,96,1303,128,1337,128C1371.4,128,1406,96,1423,80L1440,64L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path></svg>              
              </div>
              <div className="card-body">
                <h2>{Translate[lang].leave}</h2>
                <i className='la la-users'></i>
                <div>
                  <span className="fs-16 font-w600">
                    {formData.totalLeave} {Translate[lang]?.employees_on_vacation}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="card">
            <div class="wave-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fill-opacity="1" d="M0,192L17.1,192C34.3,192,69,192,103,170.7C137.1,149,171,107,206,122.7C240,139,274,213,309,218.7C342.9,224,377,160,411,154.7C445.7,149,480,203,514,234.7C548.6,267,583,277,617,250.7C651.4,224,686,160,720,138.7C754.3,117,789,139,823,160C857.1,181,891,203,926,197.3C960,192,994,160,1029,138.7C1062.9,117,1097,107,1131,96C1165.7,85,1200,75,1234,85.3C1268.6,96,1303,128,1337,128C1371.4,128,1406,96,1423,80L1440,64L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path></svg>              
              </div>
              <div className="card-body">
                <h2>{Translate[lang].assets}</h2>
                <i class="flaticon-381-notepad"></i>
                <div>
                  <span className="fs-16 font-w600">
                    {formData.totalAssets} {Translate[lang]?.assets}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="card">
            <div class="wave-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fill-opacity="1" d="M0,192L17.1,192C34.3,192,69,192,103,170.7C137.1,149,171,107,206,122.7C240,139,274,213,309,218.7C342.9,224,377,160,411,154.7C445.7,149,480,203,514,234.7C548.6,267,583,277,617,250.7C651.4,224,686,160,720,138.7C754.3,117,789,139,823,160C857.1,181,891,203,926,197.3C960,192,994,160,1029,138.7C1062.9,117,1097,107,1131,96C1165.7,85,1200,75,1234,85.3C1268.6,96,1303,128,1337,128C1371.4,128,1406,96,1423,80L1440,64L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path></svg>              
              </div>
              <div className="card-body">
                <h2>{Translate[lang].admins}</h2>
                <i className='la la-user-shield'></i>
                <div>
                  <span className="fs-16 font-w600">
                    {formData.totalAdmins} {Translate[lang]?.admins}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="card">
            <div class="wave-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fill-opacity="1" d="M0,192L17.1,192C34.3,192,69,192,103,170.7C137.1,149,171,107,206,122.7C240,139,274,213,309,218.7C342.9,224,377,160,411,154.7C445.7,149,480,203,514,234.7C548.6,267,583,277,617,250.7C651.4,224,686,160,720,138.7C754.3,117,789,139,823,160C857.1,181,891,203,926,197.3C960,192,994,160,1029,138.7C1062.9,117,1097,107,1131,96C1165.7,85,1200,75,1234,85.3C1268.6,96,1303,128,1337,128C1371.4,128,1406,96,1423,80L1440,64L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path></svg>              
              </div>
              <div className="card-body">
                <h2>{Translate[lang].resignation}</h2>
                <i className='la la-clock'></i>
                <div>
                  <span className="fs-16 font-w600">
                    {formData.totalResignation} {Translate[lang]?.resignation}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default Dashboard;
