import { Card, Dropdown, Table } from "react-bootstrap"
import { Translate } from "../../../Enums/Tranlate"
import { useSelector } from "react-redux"
import { useState } from "react"
import NoData from "../../../common/NoData"
import AddAssetsModal from "../../Assets/AddAssetsModal"

const Assets = ({assets})=>{
    const [data, setData] = useState(false)
    const [hasData, setHasData] = useState(assets?.length === 0 ? 0 : 1)
    const lang = useSelector(state=> state.auth.lang)
    const [addModal, setAddModal] = useState(false)
    const [view, setView] = useState(false)
    const [item, setItem] = useState({})
    const [shouldUpdate, setShouldUpdate] = useState(false)

    return <div>
        <Card>
            <Card.Body>
                <h3 class="card-title">{Translate[lang].assets}</h3>
              {hasData === 1 && <Table responsive>
                <thead>
                  <tr className='text-center'>
                    <th>
                      <strong>I.D</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.name}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.serial_number}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.items}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.type}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.view}</strong>
                    </th>
                  </tr>
                </thead>

                <tbody className="table-body">
                    {assets?.map((item, index) =>{
                        return <tr key={index} className='text-center'>
                        <td>
                            <strong>{item?.asset?.id}</strong>
                        </td>
                        <td>
                            {item?.asset?.name}
                        </td>
                        <td>
                            {item?.asset?.serial_number || '-'}
                        </td>
                        <td>
                            <div style={{display: 'grid', gridTemplateColumns: 'auto auto auto', gap: '8px'}}>
                                {!!item?.asset?.asset_items?.length ? item?.asset?.asset_items?.map(item=>{
                                    return <span className="badge bg-primary text-white">{item?.item}</span>
                                }) : '-'}
                            </div>
                        </td>
                        <td>
                            {item?.asset?.asset}
                        </td>
                            <td>
                            <i className="la la-eye cursor-pointer" 
                                onClick={()=> {
                                    setItem(item?.asset)
                                    setAddModal(true)
                                    setView(true)
                                }} 
                                style={{
                                    fontSize: '27px', 
                                    color: '#666'
                                }}
                            ></i>
                        </td>
                        </tr>
                    })}
                </tbody>
              </Table>}
              {hasData === 0 && <div className="text-center">
                    <NoData />
                </div>}
            </Card.Body>
        </Card>
        {addModal && 
        <AddAssetsModal
          item={item} 
          addModal={addModal} 
          setShouldUpdate={setShouldUpdate}
          setAddModal={()=> {
            setAddModal(false)
            setView(false)
          }}
          view={view}
      />}
    </div>
}
export default Assets