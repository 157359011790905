const CardItem = ({item, index}) =>{
    return(
        <tr key={index} className='text-center'>
            <td>
                <strong>{item.id}</strong>
            </td>
            <td>
                {item?.name}
            </td>
            <td>{item?.job_title}</td>
            <td>{item?.department?.name}</td>
            <td>{item?.sick_leave}</td>
            <td>
                {item?.Sick_attachments?.length > 0 ? item?.Sick_attachments?.map((att=>(
                    <a href={att.url} target='_black' rel="noreferrer">
                        <i className="la la-file-pdf" style={{fontSize: '2.5rem'}}></i>
                    </a>
                ))) : '-'}
            </td>
            </tr>
    )
}
export default CardItem;