import { useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import AdminService from "../../../services/AdminService";
// import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
// import Pagination from "../../common/Pagination/Pagination";
// import { Translate } from "../../Enums/Tranlate";
// import CardItem from "./CardItem";
import "./style.scss";

const Attendance = () => {
  // const [admins, setAdmins] = useState(null);
  const [hasData, setHasData] = useState(0);
  // const [search, setSearch] = useState(null);
  // const [tab, setTab] = useState("admins");
  // const [shouldUpdate, setShouldUpdate] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  // const adminService = new AdminService();
  // const Auth = useSelector((state) => state.auth?.auth);
  // const isExist = (data) => Auth?.admin?.admin_roles?.includes(data);
  // const lang = useSelector((state) => state.auth?.lang);

  return (
    <div className="admin">
      <Card>
        <Card.Body className={`${hasData === 0 ? "text-center" : ""}`}>
          {/* {loading && (
            <div style={{ height: "300px" }}>
              <Loader />
            </div>
          )} */}
          {/* {hasData === 1 && !loading && (
            <Table responsive>
              <thead>
                <tr>
                  <th className="px-2">
                    <strong>I.D</strong>
                  </th>
                  <th className="px-2">
                    <strong>{Translate[lang]?.name}</strong>
                  </th>
                  <th className="px-2">
                    <strong>{Translate[lang]?.email}</strong>
                  </th>
                  <th className="px-2">
                    <strong>{Translate[lang]?.phone}</strong>
                  </th>
                  <th className="px-2">
                    <strong>{Translate[lang]?.permissions}</strong>
                  </th>
                  <th className="px-2">
                    <strong>{Translate[lang]?.status}</strong>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {admins?.length > 0 &&
                  admins?.map((item, index) => {
                    return (
                      <CardItem
                        key={index}
                        index={index}
                        item={item}
                        setShouldUpdate={setShouldUpdate}
                      />
                    );
                  })}
              </tbody>
            </Table>
          )} */}
          {hasData === 0 && <NoData />}
          {/* <Pagination
            setData={setAdmins}
            service={adminService}
            shouldUpdate={shouldUpdate}
            setHasData={setHasData}
            setLoading={setLoading}
            search={search}
          /> */}
        </Card.Body>
      </Card>
    </div>
  );
};
export default Attendance;
