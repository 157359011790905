import { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { Translate } from "../../../Enums/Tranlate";
import { useSelector } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import MonthDropDown from "../../../Enums/MonthDropDown";
import YearDropDown from "../../../Enums/YearDropDown";
import Loader from "../../../common/Loader";
import NoData from "../../../common/NoData";
import AllowanceService from "../../../../services/AllowanceService";
import { toast } from "react-toastify";

const Allowances = ({employee}) => {
    const initial = {
        name: '',
        type: ''
    }
    const [formData, setFormData] = useState(initial)
    const [showForm, setShowForm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [hasData, setHasData] = useState(null)
    const [allowanceOptions, setAllowanceOptions] = useState(null)
    const typesOptions = [
        {label: 'Add to Payroll', value: 'add_to_payroll', type: 'payroll'},
        {label: 'Select Range', value: 'select_range', type: 'range'},
        {label: 'Only Calculation', value: 'only_calculation', type: 'calculation'},
    ]
    const [type, setType] = useState(typesOptions[0])
    const lang = useSelector(state=> state.auth.lang)
    const initialParams = {
        month_from: "",
        year_from: "",
        month_to: "",
        year_to: "",
    }
    const [params, setParams] = useState(initialParams)
    const allowanceService = new AllowanceService()

    useEffect(()=>{
        allowanceService.getList().then(res=>{
            if(res?.status){
                let data = res?.data?.data?.data?.map(info =>{
                    return {
                        label: info?.name,
                        value: info?.name,
                        fee: info?.fee,
                        id: info?.id
                    }
                })
                setAllowanceOptions(data)
            }
        })
    },[])

    useEffect(()=>{
        let typeVal = type?.type
        setLoading(true)
        allowanceService.getAllowanceForEmp(employee?.id, typeVal).then(res=>{
            if(res?.status){
                let data = res?.data?.data?.data
                setData(data)
                data?.length === 0 ? setHasData(0) : setHasData(1)
                let total = data?.map(tot => tot.fee).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                setTotal(total)
            }
            setLoading(false)
        }).catch(()=> setLoading(false))
    },[type])

    const changeParams = (e, name, type) => {
        setParams({...params, [`${name}_${type}`]: e})
    } 

    const onSubmit = (e) => {
        e.preventDefault()

        let data = {
            employee_id: employee?.id,
            allowance_id: formData?.name?.id,
            type: formData?.type?.type,
            month_from: params?.month_from?.value,
            year_from: params?.year_from?.value,
        }

        if(formData?.type?.type !== 'payroll'){
            data['month_to'] = params?.month_to?.value
            data['year_to'] = params?.year_to?.value
        }
        allowanceService.allowanceForEmp(data).then(res=>{
            if(res?.status){
                toast.success(`${Translate[lang].add} ${Translate[lang].successfully}`)
                setFormData(initial)
                setParams(initialParams)
                setType(formData?.type)
            }
        }).catch(e => console.error(e))
    }

    return <>
    <Card>
        <Card.Body>
            <Button variant="primary" onClick={()=> setShowForm(true)}>
                {Translate[lang].assign_allowances}
            </Button>
            {showForm && <AvForm onValidSubmit={onSubmit} className='mt-5'>
            <div className="row">
                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                    <label>{Translate[lang].allowances}</label>
                    <Select
                        placeholder={Translate[lang]?.select}
                        options={allowanceOptions}
                        value={formData?.name}
                        onChange={(e) => setFormData({...formData, name: e})}
                    />
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                    <label>{Translate[lang].type}</label>
                    <Select
                        placeholder={Translate[lang]?.select}
                        options={typesOptions}
                        value={formData?.type}
                        onChange={(e) => setFormData({...formData, type: e})}
                    />
                </div>
                <div className="col-lg-3 col-sm-6 col-6">
                        <label>{Translate[lang].from } &nbsp;</label>
                        <MonthDropDown
                            params={params} 
                            changeParams={(e, name) => changeParams(e, name, 'from')} 
                        />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-6">
                        <label>{Translate[lang].from } &nbsp;</label>
                        <YearDropDown
                            params={params} 
                            changeParams={(e, name) => changeParams(e, name, 'from')} 
                        />
                    </div>
                {(!!formData?.type?.value && formData?.type?.value !== 'add_to_payroll') && <>
                    <div className="col-lg-3 col-sm-6 col-6">
                        <label>{Translate[lang].to } &nbsp;</label>
                        <MonthDropDown
                            params={params} 
                            changeParams={(e, name) => changeParams(e, name, 'to')} 
                        />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-6">
                        <label>{Translate[lang].to } &nbsp;</label>
                        <YearDropDown
                            params={params} 
                            changeParams={(e, name) => changeParams(e, name, 'to')} 
                        />
                    </div>
                </>}
            </div>
         <div className="d-flex justify-content-between mt-3">
            <div></div>
            <Button variant="primary" type="submit" disabled={loading}>{Translate[lang]?.submit}</Button>
         </div>
      </AvForm>}
        </Card.Body>
    </Card>
    <Card>
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h3 class="card-title mb-0">{Translate[lang].allowances}</h3>
                    <Select
                        value={type}
                        className="w-25"
                        placeholder={Translate[lang].select}
                        options={typesOptions}
                        onChange={e=> setType(e)}
                    />
                </div>
                {loading && <div style={{height: '300px'}}>
                    <Loader />
                </div>}
              {(hasData === 1 && !loading) && <Table responsive>
                <thead>
                  <tr className='text-center'>
                    <th>
                      <strong>I.D</strong>
                    </th>
                    <th>
                      <strong>{type?.type !== 'payroll' && Translate[lang]?.from} {Translate[lang]?.month}</strong>
                    </th>
                    <th>
                      <strong>{type?.type !== 'payroll' && Translate[lang]?.from} {Translate[lang]?.year}</strong>
                    </th>
                    {type?.type !== 'payroll' && <>
                        <th>
                        <strong>{Translate[lang]?.to} {Translate[lang]?.month}</strong>
                        </th>
                        <th>
                        <strong>{Translate[lang]?.to} {Translate[lang]?.year}</strong>
                        </th>
                    </>}
                    <th>
                      <strong>{Translate[lang]?.allowances}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.fee}</strong>
                    </th>
                  </tr>
                </thead>

                <tbody className="table-body">
                    {data?.map((item, index) =>{
                        return <tr key={index} className='text-center'>
                            <td>
                                <strong>{item.id}</strong>
                            </td>
                            <td>
                                {item?.month_from}
                            </td>
                            <td>
                                {item?.year_from}
                            </td>
                            {type?.type !== 'payroll' && <>
                                <td>
                                    {item?.month_to}
                                </td>
                                <td>
                                    {item?.year_to}
                                </td>
                            </>}
                            <td>
                                {item?.name}
                            </td>
                            <td>
                                {item?.fee}
                            </td>
                        </tr>
                    })}
                    <tr className='text-center'>
                        <td></td>
                        <td></td>
                        <td></td>
                        {type?.type !== 'payroll' && <>
                            <td></td>
                            <td></td>
                        </>}
                        <td>
                            <strong>
                                {Translate[lang].total}:
                            </strong>
                        </td>
                        <td>
                            {total}
                        </td>
                    </tr>
                </tbody>
              </Table>}
              {(hasData === 0 && !loading) && <div className="text-center">
                    <NoData />
                </div>}
            </Card.Body>
        </Card>
    </>
}
export default Allowances;