import { Button, Col, Modal, Row } from "react-bootstrap"
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";

const ViewModal = ({viewModal, setViewModal, item})=>{
    const lang = useSelector(state=> state.auth.lang)

    return(
        <Modal className={lang === 'en' ? "en fade addProduct" : "ar fade addProduct"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={viewModal} onHide={()=>{
            setViewModal()
            }}>
            <Modal.Header>
            <Modal.Title>{Translate[lang]?.view} {Translate[lang]?.department}</Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                onClick={()=>{
                    setViewModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                    {item?.employees?.length && item?.employees?.map(emp => {
                        return <Col md={6} className="mb-2">
                            <div style={{padding: '10px', border: '1px solid #dedede', borderRadius:'8px'}}>
                                <label className="m-0">{Translate[lang].name}:</label>
                                <p className="mb-1 text-black">
                                    {emp?.name}
                                </p>
                                <label className="m-0">{Translate[lang].job_title}:</label>
                                <p className="m-0 text-black">
                                    {emp?.job_title}
                                </p>
                            </div>
                            </Col>
                    })}
                    </Row>
                    
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={()=> setViewModal()} variant="danger light">
            {Translate[lang]?.close}
            </Button>
            </Modal.Footer>
        </Modal>)
}

export default ViewModal;