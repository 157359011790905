import BaseService from "./BaseService";
import http from './HttpService'
import { API_BASE_URL_ENV } from "../jsx/common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/employee/salary";
const apiSalarySlipEndpoint = API_BASE_URL_ENV() + "/employee/salarySlip";

export default class SalariesService extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  getSalarySlip(id, params){
    return http.get(`${apiSalarySlipEndpoint}/${id}`, {params})
  }
}