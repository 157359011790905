import React, { Fragment } from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Translate } from "../../Enums/Tranlate";
import CertificateModal from "./CertificateModal";
import logo from '../../../images/logo-color.svg'

const Certificate = () => {
    const [employee, setEmployee] = useState("")
    const lang = useSelector(state=> state.auth?.lang)
    const [modal, setModal] = useState(false)

    const printProjects = () => {
      let printWindow = window.open("", "_blank");
      let htmlCode = `
        <html>
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Salary Certificate</title>
            </head>
            <body style="direction: ${lang==='en' ? 'ltr' : 'rtl'}; font-family: Cairo, sans-serif; padding: 0; margin: 3rem auto; width: 85%;">
                <div style='text-align: left'>
                    <img src=${logo} alt='logo' style="height: 100px; margin-top: 24px" />  
                </div>  
                <div style="font-size: 22px; margin-top: 3rem">
                <p style="margin-bottom: 1rem">Date: ${new Date().getDate()} / ${new Date().getMonth()+1} / ${new Date().getFullYear()}</p>
                <p style="margin-bottom: 1rem">TO WHOM IT MAY CONCERN</p>
                <p style="margin-bottom: 1rem">Cloud Lift Solutions Company certifies that Mr. / Miss ${employee?.name}, who holds ${employee?.nationality} nationality and has a civil ID ${employee?.civil_id}, works for us in the position of ${employee?.job_title} in the ${employee?.department?.name} department and is registered with it as of the date of ${new Date().getDate()} / ${new Date().getMonth()+1} / ${new Date().getFullYear()}. He/ She receives a monthly salary of ${employee?.salary} only.</p>
                <p style="margin-bottom: 2rem">This certificate was given to him upon his/her request to present it to whomever it may concern, without accepting any liability on behalf of this letter on our company.</p>
                <p>CEO Signature: </p>
              </div>
                
            </body>
        </html>
    `;
    printWindow.document.write(htmlCode);

    printWindow.document.close();

    setTimeout(() => {
      printWindow.print();
    }, 1500);
    }

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Body className="d-flex justify-content-between p-3 align-items-center">
          <div className="input-group w-50"></div>
          <div>
            {!!employee && <Button variant="secondary" className='mx-2' onClick={printProjects}>
                {Translate[lang]?.print}
            </Button>}
            <Button variant="primary" onClick={()=> {
              setModal(true) 
            }}>
                {Translate[lang]?.salary_certificate} 
            </Button>
            
          </div>
        </Card.Body >
      </Card>
      
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              {!!employee && <div className="certificate w-75">
                <p>Date: {new Date().getDate()} / {new Date().getMonth()+1} / {new Date().getFullYear()}</p>
                <p>TO WHOM IT MAY CONCERN</p>
                <p>Cloud Lift Solutions Company certifies that Mr. / Miss {employee?.name}, who holds {employee?.nationality} nationality and has a civil ID {employee?.civil_id}, works for us in the position of {employee?.job_title} in the {employee?.department?.name} department and is registered with it as of the date of {new Date().getDate()} / {new Date().getMonth()+1} / {new Date().getFullYear()}. He/ She receives a monthly salary of {employee?.salary} only.</p>
                <p>This certificate was given to him upon his/her request to present it to whomever it may concern, without accepting any liability on behalf of this letter on our company.</p>
                <p>CEO Signature: _______________________</p>
              </div>}
              {!employee && <div className="certificate w-75">
                <p>Date: {new Date().getDate()} / {new Date().getMonth()+1} / {new Date().getFullYear()}</p>
                <p>TO WHOM IT MAY CONCERN</p>
                <p>Cloud Lift Solutions Company certifies that Mr. / Miss___________________, who holds _____________ nationality and has a civil ID__________________, works for us in the position of ___________________ in the _______________ department and is registered with it as of the date of {new Date().getDate()} / {new Date().getMonth()+1} / {new Date().getFullYear()}. He/ She receives a monthly salary of _______________ only.</p>
                <p>This certificate was given to him upon his/her request to present it to whomever it may concern, without accepting any liability on behalf of this letter on our company.</p>
                <p>CEO Signature: _______________________</p>
              </div>}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {modal && 
        <CertificateModal
          modal={modal} 
          setEmployee={setEmployee}
          setModal={()=> setModal(false)}
      />}

    </Fragment>
  );
};

export default Certificate;
