export const Expenses = [
    {label: 'Servers', value: 'servers'},
    {label: 'Rent', value: 'rent'},
    {label: 'Startionary', value: 'startionary'},
    {label: 'Cleaning', value: 'cleaning'},
    {label: 'Gas Card Qty Amount', value: 'gas_card_qty_amount'},
    {label: 'Ads', value: 'ads'},
    {label: 'Gift', value: 'gift'},
    {label: 'Salary', value: 'salary'},
    {label: 'Lawyer Fees', value: 'lawyer_fees'},
    {label: 'Sim Charges', value: 'sim_charges'},
    {label: 'Tickets', value: 'tickets'},
    {label: 'Digital License', value: 'digital_license'},
    {label: 'Electricity-water', value: 'electricity_water'},
    {label: 'Compnay installation', value: 'compnay_installation'},
    {label: 'Furniture', value: 'furniture'},
    {label: 'Employee Residency', value: 'employee_residency'},
    {label: 'Internet fees', value: 'internet_fees'},
    {label: 'Printer Ink', value: 'printer_ink'},
    {label: 'Printer', value: 'printer'},
]